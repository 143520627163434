<template>
  <div class="card card-primary card-outline">
    <div class="card-header">
      <h5 class="card-title m-0">
        <i class="fa fa-share-square-o"></i> List Shortcut
      </h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-8">
          <div class="alert alert-danger" v-if="errorOccur.status">
            {{ errorOccur.message }}
          </div>
          <template v-if="onProcess">
            <b-skeleton
              type="input"
              v-for="i in skeleton"
              :key="i"
              class="mb-2"
            ></b-skeleton>
          </template>

          <template v-else>
            <form id="shortcutForm" class="d-flex flex-column">
              <div
                class="d-flex flex-column"
                v-for="shortcut in shortcuts"
                :key="shortcut.id"
              >
                <div class="row d-flex align-items-center my-2">
                  <div class="col-sm-3 d-flex align-items-center">
                    <label class="mb-0"
                      >Shortcut &nbsp;
                      <code v-html="shortcut.shortcut.toUpperCase()"></code
                    ></label>
                  </div>
                  <div class="col-sm-9">
                    <select
                      :name="`${shortcut.id}`"
                      class="form-control select2"
                    >
                      <template v-for="canned in canneds">
                        <option
                          :value="canned.id"
                          :key="canned.id"
                          :selected="shortcut.cannedid === canned.id"
                        >
                          {{ canned.title }} -
                          <span class="small">{{ canned.title }}</span>
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-info shadow-none"
                type="button"
                @click="updateShortcut()"
              >
                <i class="fa fa-save"></i> Simpan
              </button>
            </form>
          </template>
        </div>
        <div class="col-sm-4">
          <div class="card shadow-sm">
            <div class="card-body">
              <h5>Informasi</h5>
              <ul class="pl-3">
                <li>
                  Mohon lakukan hard refresh <code>(CTRL+SHIFT+R)</code> pada
                  halaman <a href="/agent" target="_blank">chat</a> setelah
                  melakukan perubahan
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
import { request, util } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "Shortcut",
  mixins: [request, loader, util],
  data() {
    return {
      shortcuts: [],
      canneds: [],
      skeleton: 10,
      onProcess: true,
      errorOccur: {
        status: false,
        message: "Internal error",
      },
    };
  },
  methods: {
    getShortcut: function () {
      this.API.get(this.URL.canned.shortcut, "?current=0")
        .then(({ data }) => {
          if (typeof data.shortcut !== "undefined") {
            this.shortcuts = data.shortcut;
          }
          if (typeof data.canned !== "undefined") {
            this.canneds = data.canned;
          }
        })
        .catch((error) => {
          this.errorOccur.status = true;
          this.errorOccur.message = error.message;
        })
        .finally(() => {
          setTimeout(() => {
            this.onProcess = false;
          }, 1000);
        });
    },

    updateShortcut: function () {
      let params = jQuery("#shortcutForm").serialize();
      params = Object.fromEntries(new URLSearchParams(params));

      this.loading();
      this.API.post(this.URL.canned.shortcutUpdate, {
        shortcut: params,
      })
        .then(() => {
          this.$bvToast.toast(`Shortcut canned berhasil diperbarui`, {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
          });
        })
        .catch((error) => {
          let message = this.handleCatchAxios("shortcut", error, true);
          this.$swal("Error", message, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  mounted() {
    this.getShortcut();
    this.$store.dispatch("breadcrumb", {
      title: "Shortcut Canned",
      path: "Shortcut",
    });
    this.$store.dispatch("activeLink", this.$route.path);
  },
};
</script>
